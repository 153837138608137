(function () {
    "use strict";
    var app = angular.module('nav', ['ui.router', 'home', 'services', 'about']);

    app.config(function ($stateProvider, $urlRouterProvider, $locationProvider, $uiViewScrollProvider) {

        $locationProvider.html5Mode({
            enabled: true,
            requireBase: false
        }).hashPrefix('!');

        $uiViewScrollProvider.useAnchorScroll();

        $urlRouterProvider.otherwise('/');

        //Pages
        $stateProvider.state('root', {
            abstract: true,
            url: '',
            templateUrl: 'app/pages/abstract/body.html',
            controller: "body"
        });

        $stateProvider.state('root.page', {
            abstract: true,
            url: '',
            template: '<ui-view/>'
        });
    });
})();