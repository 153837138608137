(function () {
	var app = angular.module('backstretch', []);

	app.directive('backstretch', function () {
		return {
			restrict: 'A',
			link: function (scope, element, attr) {
				element.backstretch(attr.backgroundUrl);
			}
		}
	});

	app.directive('onScroll', function($window) {
		return {
			restrict: 'A',
			link: function(scope, element, attr) {
				angular.element($window).bind('scroll', function() {
					element.css('top', $window.scrollY);
					element.css('bottom', 0 - $window.scrollY);
				});
			}
		}
	});
})();