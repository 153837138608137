(function () {
    "use strict";

    var app = angular.module('home', []);

    app.config(function ($stateProvider, $urlRouterProvider, $locationProvider, $uiViewScrollProvider) {

        $stateProvider.state('root.page.index', {
            url: '/',
            templateUrl: 'app/pages/home/home.html',
            controller: "home.controller"
        });

    });
})();
