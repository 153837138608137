(function () {
    "use strict";
    //$.backstretch('Content/Images/computers.jpg');

    var app = angular.module('app', ['ngMaterial', 'pascalprecht.translate', 'nav', 'backstretch']);

    app.run(function ($rootScope, $window, $log, $location) {

        //Force SSL if on public site
        /*if (($location.protocol() !== 'https') && ($location.host() != 'localhost')) {
            $log.debug("Forcing SSL because we are public");
            $window.location.href = $location.absUrl().replace('http', 'https');
        }*/

        //Helper
        $rootScope.inArray = function (item, array) {
            return (-1 !== array.indexOf(item));
        };

        $rootScope.allSites = [
            { 'title': "Hem", 'link': 'root.page.index' },
            { 'title': "Tjänster", 'link': 'root.page.services' },
            { 'title': "Om oss", 'link': 'root.page.about' }
        ];

        $rootScope.backgroundImage = 'Content/Images/computers.jpg';
        
        //Navigation
        $rootScope.$on('$stateChangeStart', function (event, next, nextParams, from, fromParams) {
            if (!event.defaultPrevented) {
                $rootScope.loadingView = false;
                $rootScope.viewRejected = false;
                $log.debug("Starting navigation to \"" + next.name + "\" from \"" + from.name + "\"");
            }
        });

        $rootScope.$on('$stateChangeSuccess', function (event, next, nextParams, from, fromParams) {
            $rootScope.loadingView = false;
            $log.debug("Successfully navigated to \"" + next.name + "\" from \"" + from.name + "\"");
            $window.scrollTo(0, 0);
        });

        $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
            $rootScope.loadingView = false;
            $log.debug("Error when navigating from \"" + fromState.name + "\" to \"" + toState.name + "\" with error: ");
            $log.debug(error);
        });
    });

    app.config(function ($translateProvider, $logProvider, $httpProvider) {
        
        //Translate
        $translateProvider.useSanitizeValueStrategy('sanitizeParameters');

        $translateProvider.translations('en', {});

        $translateProvider.translations('sv', {});

        $translateProvider.preferredLanguage('sv');
    });

    app.controller('body', function ($scope, $translate, $state, $log) {

        $scope.language = (($scope.language == undefined) ? 'sv' : $scope.language);
        $translate.use($scope.language);

        $scope.adressItems = [
            { "Icon": "location_on", "Html": "Altarbacken 6, 423 49 Torslanda" },
            { "Icon": "email", "Html": "<a href='mailto:kontakt@khoi.se' alt='Khoi contact email'>kontakt@khoi.se</a>" }
        ];

        $scope.changeLanguage = function () {
            $log.debug("Changing language to " + $scope.language);
            $scope.language = (($scope.language == 'sv') ? 'en' : 'sv');
            $state.reload();
        };

        $scope.openMenu = function($mdMenu, ev) {
            $mdMenu.open(ev);
        };
    });
})();