(function () {
    "use strict";

    var app = angular.module('about', []);

    app.config(function ($stateProvider, $urlRouterProvider, $locationProvider, $uiViewScrollProvider) {

        $stateProvider.state('root.page.about', {
            url: '/about',
            templateUrl: 'app/pages/about/about.html',
            controller: "about.controller"
        });

    });
})();
