(function () {
    "use strict";

    var app = angular.module('about');

    app.controller('about.controller', function ($scope, $state) {

    });

})();
