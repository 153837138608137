(function () {
    var app = angular.module('full-screen.directive', []);

    app.directive('fullScreen', function ($window) {
        return function (scope, element, attrs) {
            angular.element($window).bind("load", function () {
                var applicationHeight = angular.element('#application')[0].offsetHeight,
                    delta = $window.innerHeight - applicationHeight;
                
                if (delta > 0) {
                    var content = angular.element('.content');
                    var offsetHeight = content[0].offsetHeight;
                    $('.content').css('height', offsetHeight + delta);
                }
            });
        };
    });
})();