(function () {
    var app = angular.module('shrink.directive', []);

    app.directive("shrink", function ($window) {
        return function (scope, element, attrs) {
            angular.element($window).bind("scroll resize", function () {
                if ($window.innerWidth < 960) {
                    $(element).css("width", "150px");
                } else {
                    if (this.pageYOffset >= 50) {
                        $(element).css("width", "150px");
                    } else {
                        $(element).css("width", "300px");
                    }
                }
            });
        };
    });
})();