(function () {
    "use strict";

    var app = angular.module('services', []);

    app.config(function ($stateProvider, $urlRouterProvider, $locationProvider, $uiViewScrollProvider) {

        $stateProvider.state('root.page.services', {
            url: '/services',
            templateUrl: 'app/pages/services/services.html',
            controller: "services.controller"
        });

    });
})();
