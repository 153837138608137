(function () {
    "use strict";

    var app = angular.module('services');

    app.controller('services.controller', function ($scope, $state) {

    });

})();
